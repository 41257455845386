import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import orange from '@material-ui/core/colors/orange';
import pink from '@material-ui/core/colors/pink';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';

export default {
    red: red[500],
    white: "white",
    black: "black",
    textGray: grey[800],
    purple: purple[800],
    lightPurple: purple[600],
    veryLightPurple: purple[400],
    orderButtons: grey[600],

    organizationIconColor: indigo[600],
    orderIconColor: orange[500],
    publicCourseParticipantIconColor: red[800],

    infoBoxes: {
        orange: orange[600],
        pink: pink[600],
        lightBlue: lightBlue[700],
        green: green[500],
    }
}