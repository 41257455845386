import React from 'react';
import SavePublicCourseButton from './SavePublicCourseContainer';
import {ActionButtonsBox} from "../../../../components/ActionButtons/ActionButtonsBox";

export default function PublicCourseActionButtons() {
    return (
        <ActionButtonsBox>

            <SavePublicCourseButton/>

        </ActionButtonsBox>
    );
}